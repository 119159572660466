import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpRequestService } from 'src/app/core/services/http-request.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  animations: [
        trigger('contentExpansion', [
            state('expanded', style({ height: '*', opacity: 1, visibility: 'visible', marginTop: '20px' })),
            state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden', marginTop: '0px' })),
            transition('expanded <=> collapsed',
                animate('300ms cubic-bezier(.37,1.04,.68,.98)')),
        ]),
        trigger('btnDefaultState', [
            state('show', style({ opacity: 1, transform: 'rotate(0deg) scale(1)' })),
            state('hide', style({ opacity: 0, transform: 'rotate(180deg) scale(.5)' })),
            transition('show => hide', animate('300ms')),
            transition('hide => show', animate('300ms'))
        ]),

        trigger('btnOpenState', [
            state('show', style({ opacity: 1, transform: 'rotate(0deg) scale(1)' })),
            state('hide', style({ opacity: 0, transform: 'rotate(-180deg) scale(.5)' })),
            transition('show => hide', animate('300ms')),
            transition('hide => show', animate('300ms'))
        ]),

    ]
})
export class SupportComponent  {
   submitted = false;
   panelOpen = false;
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    file: any = null;
    loading = false;
    showSuccess = false;

    supportform: FormGroup = this.formBuilder.group({
        title: ['', Validators.required],
        message: ['', Validators.required],
        type: [0, [Validators.required, Validators.min(1)]],
    });

    constructor(
        private http: HttpRequestService,
        private formBuilder: FormBuilder
    ) { }

    closePanel() {
        this.panelOpen = false;
    }

    btnPanel() {
        if (this.showSuccess) {
            this.showSuccess = false;
            this.panelOpen = false;
            return;
        }
        if (this.loading) {
            return;
        }
        if (!this.panelOpen) {
            this.panelOpen = true;
            return;
        }
        this.submit();
    }

    submit() {
        this.submitted = true;
        if (!this.supportform.valid) {
            return;
        }
        this.loading = true;
        this.supportform.disable();
        const params = {
            title: this.supportform.get('title').value,
            message: this.supportform.get('message').value,
            type: this.supportform.get('type').value
        };
        this.http.post(`clients/support/sendemail`, params, false, this.file).subscribe({
            next: d => {
                this.loading = false;
                this.clearForm();
                this.showSuccess = true;
            },
            error: err => {
                this.loading = false;
            }
        });
         this.submitted = false;
    }

    clearForm() {
        this.supportform.patchValue({
            title: '',
            message: '',
            type: 0
          });
        this.file = null;
        this.supportform.enable();
    }
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    prepareFilesList(files) {
        this.file = files[0];
        this.fileDropEl.nativeElement.value = '';
    }

    removeFile() {
        this.file = null;
    }

}
