import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LayoutModule} from './components/layout/layout.module';
import {UtilsService} from './core/services/utils.service';
import {HttpRequestService} from './core/services/http-request.service';
import {CustomValidatorService} from './core/services/custom-validator.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { ModalQuestionnaireComponent } from './components/questionnaire/page/modal-questionnaire/modal-questionnaire.component';
import { ModalClientComponent } from './components/clients/components/modal-client/modal-client.component';
import { DirectivesModule } from './shared/directive/directive.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule } from './shared/shared.module';
import { LoaderModule } from './shared/loader/loader.module';
import { DndDirective } from './core/direcitves/dnd.directive';
import { QuillModule } from 'ngx-quill';
import { ClientHistoryComponent } from './components/clients/components/client-history/client-history.component';
import {TableModule} from 'primeng/table';
import {AuthGuard} from './core/guards/auth-guard';

@NgModule({
  declarations: [
    AppComponent,
    ModalQuestionnaireComponent,
    ModalClientComponent,
    DndDirective,
    //ClientHistoryComponent
    // CountriesComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        LayoutModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule,
        TooltipModule,
        SkeletonModule,
        SharedModule,
        DirectivesModule,
        ProgressSpinnerModule,
        LoaderModule,
        QuillModule.forRoot(),
        TableModule
    ],
  providers: [
    UtilsService,
    HttpRequestService,
    CustomValidatorService, AuthGuard
  ],
  exports: [
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
