import { Component } from '@angular/core';
/**
 * The app component
 *
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  /**
   * title
   * @type {any}
   * @memberof title
   */
  title = 'frontend';
}
