import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { HttpRequestService } from 'src/app/core/services/http-request.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss']
})
export class ProfileDropdownComponent implements OnInit {
  items: MenuItem[];
  @Input() label: string;
  @Input() photo: string;
  notImage = '../../../../assets/images/not-image.jpg'
  isPremiun: boolean = false;
  constructor(private router: Router, private primengConfig: PrimeNGConfig, private utilService: UtilsService, private http: HttpRequestService) { }

  ngOnInit(): void {
    this.getCustomerDetail()
    this.utilService.observableMessage.subscribe(image => {
      if (image && image.type === 'imageProfile') {
        this.photo = image.advisor_image_url;
        localStorage.setItem('photo', this.photo);
      } else if (image && image.type === 'reloadProfile') {
        if (!this.isPremiun){
          this.getCustomerDetail();
        }
      }
    })
    this.primengConfig.ripple = true;
    this.items = [{
      items: [{
        label: 'Profile',
        command: () => {
          this.redirect('/profile');
        }
      },
      {
        label: 'Advanced',
        command: () => {
          this.redirect('/profile/advanced');
        },
        visible: this.isPremiun
      },
      {
        label: 'Logout',
        command: () => {
          this.singOut();
        }
      }
      ]
    }
    ];
  }

  redirect(url) {
    this.router.navigate([url]);
  }

  singOut() {
    const self = this;
    localStorage.clear();
    this.cleanSession();
    self.router.navigate(['/login']);

  }

  cleanSession() {
    sessionStorage.clear();
  }

  getCustomerDetail() {
    let customerId = localStorage.getItem('customer_id')
    if (customerId) {
      this.http.get(`subscriptions/customers/${customerId}`).subscribe(customer => {
        if (customer && customer.length > 0) {
          customer.sort((a, b) => {
            if (a.unit_amount < b.unit_amount) {
              return 1;
            }
            if (a.unit_amount > b.unit_amount) {
              return -1;
            }
            return 0;
          })
          this.getProducts(customer[0].product_id)
        }
      })
    }
  }

  getProducts(product_id) {
    this.http.get(`products/${product_id}`).subscribe(product => {
      if (product && product.length > 0) {
        this.isPremiun = !!product.find(x => x?.metadata?.constanName === 'full plan');
        this.items = [{
          items: [{
            label: 'Profile',
            command: () => {
              this.redirect('/profile');
            }
          },
          {
            label: 'Customizations',
            command: () => {
              this.redirect('/profile/advanced');
            },
            visible: this.isPremiun
          },
            {
              label: 'Customizations',
              command: () => {
                this.redirect('/profile/basic');
              },
              visible: !this.isPremiun
            },
          {
            label: 'Logout',
            command: () => {
              this.singOut();
            }
          }
          ]
        }
        ];
      }
    })
  }

}
