import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { ExpiredTokenComponent } from './components/expired-token/expired-token.component';
import { CountriesComponent } from '../components/countries/countries.component';


@NgModule({
  declarations: [ExpiredTokenComponent, CountriesComponent],
  imports: [
    CommonModule,
    SharedRoutingModule
  ],
  exports:[
    ExpiredTokenComponent, CountriesComponent
  ]
})
export class SharedModule { }
