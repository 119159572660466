import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Authentication Services
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * token
   * user's session token
   * @type {any}
   * @memberof token
   */
  token: any;

  /**
   * constructor
   * Creates an instance of AuthService.
   * @memberof RpsService
   * @param  {Router} private router
   * @param  {AngularFireAuth} private angularFireAuth
   */
  constructor(private angularFireAuth: AngularFireAuth,private router: Router,) { }

  /**
   * isAuthenticated()
   * if the user is authenticated return true if not return false
   * @returns {Boolean} true or false
   * @memberof AuthService
   */
  public isAuthenticated(): boolean {
    return localStorage.getItem('username') !== null && localStorage.getItem('enabled') === 'true';
  }

  /**
   * isCustomer()
   * if the user is a customer return true if not return false
   * @returns {Boolean} true or false
   * @memberof AuthService
   */
  public isCustomer(): boolean {
    return localStorage.getItem('customer_id') !== null;
  }

  /**
   * signupAnonymo()
  * Sign up the user
  * @return the anonymous user's session token
  * @memberof AuthService
  */
  signupAnonymo(): Promise<string> {

    let subscription = new Subscription();
    return new Promise((resolve, reject) => {
      this.angularFireAuth.signInAnonymously().then(user => {
        subscription = this.angularFireAuth.idToken.subscribe((tokenId: string) => {
          this.token = tokenId;
          localStorage.setItem('accessToken', this.token);
          resolve(tokenId);
          subscription.unsubscribe();
        });
      }).catch(err => {
        reject(err);
        subscription.unsubscribe();
      });
    });
  }
  
  /**
   * deletenAnonymously()
   * @returns {Promise<void>} anonymous user delete confirmation
   * @memberof AuthService
   */
  deletenAnonymously(): Promise<string> {
    let subscription = new Subscription();
    return new Promise((resolve, reject) => {
      this.angularFireAuth.currentUser.then(user => {
        if(user){
          user.delete()
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      }).catch(err => {
        reject(err);
        subscription.unsubscribe();
      });
    });

  }
}
