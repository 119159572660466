import {environment} from '../../environments/environment';

const backendUrl = environment.services.apiUrl;
export const Constants = {
    endpoint: {
        questions: backendUrl + '/questions',
        questionsForm: backendUrl + '/questions/form',
        action_plan: backendUrl + '/action_plan',
        typeform: backendUrl + '/webhook/typeform'
    },
    dashboard: {
        cards: {
            order: ['Number of Clients', 'Completed Questionnaires', 'Platinum Subscription', 'Bronze Subscription']
        }
    }

};