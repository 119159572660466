import {NgModule} from '@angular/core';
import {LayoutComponent} from './page/layout.component';
import {LayoutRoutingModule} from './layout-routing.module';
import {CommonModule} from '@angular/common';
import { ProfileDropdownComponent } from 'src/app/shared/profile-dropdown/profile-dropdown.component';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { HelpModalComponent } from 'src/app/shared/help-modal/help-modal.component';
import { SupportComponent } from '../../support/support.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    LayoutComponent,
    ProfileDropdownComponent,
    HelpModalComponent,
    SupportComponent
  ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        LayoutRoutingModule,
        CommonModule,
        MenuModule,
        RippleModule,
        ButtonModule    ]    
})

export class LayoutModule {}
