import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { RpsService } from 'src/app/core/services/rps.service';
/**
 * The CountriesComponent Component
 * @implements OnInit
 * @class CountriesComponent
 */
@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent implements OnInit {
   /**
   * countrySelected
   * @type {Output}
   * @memberof countrySelected
   */
  @Output() countrySelected = new EventEmitter();
  /**
   * countries
   * @type {any[]}
   * @memberof countries
   */
  countries: any[];
  /**
   * flagUrl
   * @type {string}
   * @default 'assets/us.svg'
   * @memberof flagUrl
   */
  flagUrl = 'assets/us.svg';

  /**
   * constructor
   * Creates an instance of CountriesComponent.
   * @param rpsService 
   */
  constructor(private rpsService: RpsService) {
  }

  /**
   * call to all countries service
   * @memberof CountriesComponent
   */
  ngOnInit() {
    this.getCountries();
  }

  /**
   * getCountries()
   * @return {any[]} all countries with prefix
   * @memberof CountriesComponent
   */
  getCountries() {
    this.rpsService.getCountries().subscribe((res: any[]) => {
      this.countries = res;
      this.countries.forEach(country => {
        const iid = country.idd.root;
        const suffix = country.idd.suffixes && country.idd.suffixes.length === 1 ? country.idd.suffixes[0] : '';
        country.phonePrefix = iid + suffix;
      });
    });
  }


  /**
   * onCLick()
   * you get the flag the country and emit the phone prefix of the country 
   * @param country 
   * @memberof CountriesComponent
   */
  onCLick(country: any) {
    this.flagUrl = country.flags.svg;
    this.countrySelected.emit(country.phonePrefix);
  }
}
