import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-expired-token',
  templateUrl: './expired-token.component.html',
  styleUrls: ['./expired-token.component.scss']
})
export class ExpiredTokenComponent  {
  @Input() message: any = 'Site not active';
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.goHome();
    }
  }
  constructor(private utilService: UtilsService, private router: Router) {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    body.style['overflow-y'] = 'hidden';
    html.style['overflow-y'] = 'hidden';
    this.utilService.observableMessage.subscribe(message => {
      if (message && message.type === 'questionnaire') {
        this.message = message.message;
      }

    });
   }



  goHome() {
    window.location.href = environment.ladingUrl;
  }

}
