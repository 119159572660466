/** Dependencies */
import { NgModule} from '@angular/core';
import { PhoneMaskDirective } from './phone-mask/phone-mask.directive';

@NgModule({
  declarations:
  [ PhoneMaskDirective,
   ],
  exports: [
    PhoneMaskDirective,
  ],
  providers: [
    PhoneMaskDirective,
  ],
})
export class DirectivesModule {}
