import { Component} from '@angular/core';
import {Router} from '@angular/router';
/**
 * The LayoutComponent Component
 * @implements OnInit
 * @class LayoutComponent
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent  {

  /**
   * names
   * @type {any}
   * @memberof names
   */
  names = localStorage.getItem('username');
  /**
   * photo
   * @type {any}
   * @memberof photo
   */
  photo = localStorage.getItem('photo');
  /**
   * role
   * @type {any}
   * @memberof role
   */
  role = localStorage.getItem('pem');
  /**
   * showMenu
   * @type {boolean}
   * @memberof showMenu
   */
  showMenu: boolean;

  /**
   * constructor
   * Creates an instance of LayoutComponent.
   */
  constructor(
    private router: Router,
  ) {}


  /**
   * singOut()
   * finish user's session
   */
  singOut() {
    const self = this;
    localStorage.clear();
    self.router.navigate(['/login']);

  }

  /**
   * toggleMenu()
   * hide or show the menu
   */
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  hasFullAccess() {
    return (localStorage.getItem('type') !== 'basic_plan');
  }



}
