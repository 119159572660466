export class Client {
    id: string;
    client_name: string;
    client_last_name: string;
    client_phone: string;
    client_email: string;
    client_image_url: string;
    advisor_id: string;
    rps_date?: string;
    client_id?: string;
    client_estimated_date_ret?: string;
    type?: string;
}