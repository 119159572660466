declare var $;

export const environment = {
  production: true,
  ladingUrl:"https://rpsforme.com/",
  services:
      {
        apiUrl: 'https://cambridgeapi.herokuapp.com',
        restCountries: 'https://restcountries.com/v3.1/all',
      },
  rows_per_page : 10,
  buttons: [
    {
      extend: 'excel',
      text : 'Export to excel',
      action:
          function (e, dt, button, config) {
            const self2 = this;
            const oldStart = dt.settings()[0]._iDisplayStart;

            // tslint:disable-next-line:no-shadowed-variable
            dt.one('preXhr', function (e, s, data) {
              data.start = 0;
              data.length = 2147483647;

              // tslint:disable-next-line:no-shadowed-variable
              dt.one('preDraw', function (e, settings) {
                if (button[0].className.indexOf('buttons-excel') >= 0) {
                  if ($.fn.dataTable.ext.buttons.excelHtml5.available(dt, config)) {
                    $.fn.dataTable.ext.buttons.excelHtml5.action.call(self2, e, dt, button, config);
                  } else {
                    $.fn.dataTable.ext.buttons.excelFlash.action.call(self2, e, dt, button, config);
                  }
                } else if (button[0].className.indexOf('buttons-print') >= 0) {
                  $.fn.dataTable.ext.buttons.print.action(e, dt, button, config);
                }

// tslint:disable-next-line:no-shadowed-variable
                dt.one('preXhr', function (e, s, data) {
                  settings._iDisplayStart = oldStart;
                  data.start = oldStart;
                });

                setTimeout(dt.ajax.reload, 0);
                return false;
              });
            });

            dt.ajax.reload();
          }}
  ],
  firebaseConfig : {
    apiKey: "AIzaSyAq5fdnBdiN7hLetHzH6t-Ir2dEZ6mUwT0",
    authDomain: "cambridge-fa6f5.firebaseapp.com",
    projectId: "cambridge-fa6f5",
    storageBucket: "cambridge-fa6f5.appspot.com",
    messagingSenderId: "854644814726",
    appId: "1:854644814726:web:72a5f8c642c4cf1dc57207",
    measurementId: "G-NHHL29H4H3"
  },
  stripeKey: "pk_live_51Jpe7cBmR5AYpZnNmKLw87aFAvzVzkNzXhnwpLT2FmbWNngOpfPeTq4R23BkXyPZstWhrYRRqbTBj6EziJnGAccF00HEI8ySbX"
};