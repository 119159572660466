import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
/**
 * The phone mask
 * @class PhoneMaskDirective
 * ```html
 *  +1 (222)(222)(222)
 * ```
 * @example
 * <input phoneMask placeholder="Type your phone here..." type="text">
 */
@Directive({
  selector: '[phoneMask]'
})
export class PhoneMaskDirective{
  /**
   * constructor
   * Creates an instance of PhoneMaskDirective.
   * @memberof PhoneMaskDirective
   */
  constructor(public ngControl: NgControl) { }

  /**
   * HostListener when the value changes, it validates and transforms the entered number
   * @param event 
   */
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }
  /**
   * HostListener when the value changes, it validates the backspace and transforms the entered number
   * @param event 
   */

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }
  /**
   * validates if the field is empty, then detects the number of characters and transforms the number
   * @param event 
   * @param backspace 
   * @returns the new transform value
   */

  onInputChange(event, backspace) {
    if (!event) {
      return;
    }
    
    const elementRef = (this.ngControl.valueAccessor as any)._elementRef.nativeElement;
    const cursorStart = elementRef.selectionStart;
    const cursorEnd = elementRef.selectionEnd;

    let newVal = event.replace(/\D/g, '');
    if (backspace && newVal.length <= 3) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else if (newVal.length === 11) {
      newVal = newVal.replace(/^(\d?)(\d{0,3})(\d{0,3})(\d{0,4})/, '$1 ($2) $3-$4');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    this.ngControl.valueAccessor.writeValue(newVal);
    if (!newVal) {
      this.ngControl.control.setErrors({ required: true });
    }
    if (cursorStart < event.length) {
      elementRef.setSelectionRange(cursorStart, cursorEnd);
    }
  }
}
