import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpiredTokenComponent } from './components/expired-token/expired-token.component';

const routes: Routes = [
  {
    path: "access-denied",
    component:ExpiredTokenComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
