import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

declare const $;

@Injectable()
export class UtilsService {
  private message: BehaviorSubject<any>;
  observableMessage: Observable<any>;
  constructor(
    private location: Location,
    private router: Router,
  ) {
    this.message = new BehaviorSubject<any>(null);
    this.observableMessage = this.message.asObservable();
  }

    cleanDate(date, onlyDate) {
        const d = new Date(date);
        if (onlyDate) {
            return [(d.getMonth() + 1).toString().padStart(2, '0'), d.getDate().toString().padStart(2, '0'), d.getFullYear()].join('-');
        }
        return [(d.getMonth() + 1).toString().padStart(2, '0'), d.getDate().toString().padStart(2, '0'), d.getFullYear()].join('-')
            + ' ' + [d.getHours().toString().padStart(2, '0'), (d.getMinutes() + 1).toString().padStart(2, '0')].join(':');

    }

  ymdDate(date) {
    const d = new Date(date);
    return [(d.getMonth() + 1).toString().padStart(2, '0'), d.getDate().toString().padStart(2, '0'), d.getFullYear().toString().substr(-2)].join('/');
  }

  // START DATEPICKER
  initDatePicker(f, validator, minDate?) {
    this.configDatepicker();

    const dateProperties = {
      onSelect: function (v) {
        f.controls[$(this).attr('name')].setValue(v);
        validator.check(this);
      }
    };

    if (minDate) {
      dateProperties['minDate'] = 0;
    }

    $('.date').datepicker(dateProperties);
  }

  initDatePickerWithMaxDate(f, validator, maxDate, element) {
    this.configDatepicker();
    let dateProperties = {};

    if (f.controls[element]) {
      dateProperties = {
        onSelect: function (v) {
          f.controls[$(this).attr('name')].setValue(v);
          validator.check(this);
        }
      };
    }

    dateProperties['maxDate'] = 0;
    $('#' + element).datepicker(dateProperties);
  }

  simpleInitDatePicker() {
    this.configDatepicker();

    $('.simple-date').datepicker();
  }

  ngModelDatePicker(f, k) {
    this.configDatepicker();

    $('#' + k).datepicker({
      onSelect: function (v) {
        f[k] = v;
      }
    });
  }

  configDatepicker() {
    $.datepicker.regional['es'] = {
      changeMonth: true,
      changeYear: true,
      yearRange: '-100:+100',
      closeText: 'Cerrar',
      prevText: '< Ant',
      nextText: 'Sig >',
      currentText: 'Hoy',
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
        'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      weekHeader: 'Sm',
      dateFormat: 'dd-mm-yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
    };

    $.datepicker.setDefaults($.datepicker.regional['es']);
  }
  // END DATEPICKER

  initDataTable(c?, order?) {
    setTimeout(() => {
      c = c ? c : 'data-table';

      const options = {
        'language': {
          'lengthMenu': 'Mostrar por página: _MENU_',
          'zeroRecords': '',
          'info': 'Página _PAGE_ de _PAGES_',
          'infoEmpty': '',
          'infoFiltered': '(Filtrados del total de registros)',
          'search': 'Filtrar:',
          'paginate': {
            'first': 'Primera',
            'last': 'Última',
            'next': 'Siguiente',
            'previous': 'Anterior'
          }
        },
        'pageLength': 10,
        'deferRender': true,
      };

      if (order) {
        options['order'] = [order[0], order[1]];
      } else {
        options['bSort'] = false;
      }

      if (!$.fn.DataTable.isDataTable('.' + c)) {
        $('.' + c).DataTable(options);
      }
    }, 1);
  }

  reloadDataTable(c?) {
    c = c ? c : 'data-table';

    if (!$.fn.DataTable.isDataTable('.' + c)) {
      $('.' + c).DataTable().ajax.reloadDataTable();
    }
  }

  destroyDataTable(c?) {
    c = c ? c : 'data-table';
    if ($.fn.DataTable.isDataTable('.' + c)) {
      $('.' + c).DataTable().destroy();
    }
  }

  formatDate(d) {
    return new Date(d).toJSON().slice(0, 10).split('-').reverse().join('-');
  }

  formatCurrency(c) {
    return (c).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  getDaysHoursMinutesBetween(endDate, startDate?) {
    const response = [];

    if (startDate) {
      startDate = new Date(startDate);
    } else {
      startDate = new Date();
    }

    endDate = new Date(endDate);

    let delta = Math.abs(endDate.getTime() - startDate.getTime()) / 1000;

    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const seconds = delta % 60;

    response['days'] = days;
    response['hours'] = hours;
    response['minutes'] = minutes;
    response['seconds'] = seconds;

    return response;
  }

  back() {
    this.location.back();
  }

  redirectTo(url, section) {
    this.router.navigate([url]).then(() => {
      if (section) { $('html, body').animate({ scrollTop: $('.' + section).offset().top - 105, }, 500, 'linear'); }
    });
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  getTimeStamp(date) {
    return (new Date(date).getTime());
  }

  encodeToHexadecimal(str) {
    const arr1 = [];

    for (let n = 0, l = str.length; n < l; n++) {
      const hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }

    return arr1.join('');
  }

  decodeFromHexadecimal(str1) {
    const hex = str1.toString();
    let str = '';

    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }

    return str;
  }

  changeMessage(message: any, type:string = '') {
    if(type === 'imageProfile'){
      this.message.next(message)
    } else  if(type === 'addon'){
      this.message.next(message)
    } else if (type === 'questionnaire'){
      this.message.next(message)
    } else if (type === 'seminary'){
      this.message.next(message)
    } else if (type === 'reloadProfile') {
      this.message.next(message);
    } else if (type === 'summary') {
      this.message.next(message);
    }else{
      this.message.next(null)
    }

  }
}
