import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
      private router: Router,
      private authService: AuthService,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userRole = route.data.userRole as string;
    const actualRole = localStorage.getItem('pem') ?? '-1';

    if (this.authService.isAuthenticated() && (userRole == actualRole)) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
