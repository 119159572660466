import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalQuestionnaireComponent } from 'src/app/components/questionnaire/page/modal-questionnaire/modal-questionnaire.component';
import { Client } from 'src/app/core/models/client';
import { HttpRequestService } from 'src/app/core/services/http-request.service';

declare var $;

@Component({
  selector: 'app-users-modal-client',
  templateUrl: './modal-client.component.html',
  styleUrls: ['./modal-client.component.scss']
})
export class ModalClientComponent implements OnInit {
  modalForm: FormGroup;
  submitted: boolean;
  client: Client;
  advisorId: string;
  file: any;
  error: any;
  loading = false;
  isAdvisor: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    private http: HttpRequestService,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {
    if (localStorage.getItem('advisor_id')) {
      this.advisorId = localStorage.getItem('advisor_id');
      this.modalForm = this.formBuilder.group({
        id: [''],
        client_name: ['', Validators.required],
        client_last_name: ['', Validators.required],
        client_email: ['', [Validators.required, Validators.email]],
        client_image_url: '',
        client_phone: '',
        advisor_id: this.advisorId,
        rps_date: new Date()
      });
    }
   }

  ngOnInit() {
    const role = parseInt(localStorage.getItem('pem'), 0 );
    this.isAdvisor = (role !== 1);
    if (this.config.data) {
      this.modalForm.patchValue({
      client_name: [this.config.data.client_name],
        client_last_name: this.config.data.client_last_name,
        client_email: this.config.data.client_email,
        client_phone: this.config.data.client_phone
      });
      this.modalForm.disable();
    }
  }

  close() {
    this.ref.close();
  }

  onSelectFile(e) {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      this.file = e.target.files[0];
      reader.onload = (ev) => {
        this.modalForm.get('client_image_url').setValue(ev.target['result']);
      };
    }
  }

  onSubmit(event) {
    this.submitted = true;
    this.loading = true;
    if (this.config.data) {
      const body = {
        'client_id': this.config.data.id,
        'type': 'url_questionnaire'
      };
      this.http.post('clients/send-email', body, false, null).subscribe((client) => {
        if (client) {
          this.ref.close(this.modalForm.value);
          this.loading = false;
        }
      });

    } else {
      if (this.modalForm.invalid) {
        this.loading = false;
        return;
      }
      this.client = new Client();
      this.client = this.modalForm.value;
      this.client.type = 'internal';
      this.http.post('clients/' + (this.client.id  ?  'update' : 'create') + '-client', this.client, false, null).subscribe((client) => {
        if (client) {
          this.error = null;
          this.loading = false;
          this.ref.close(this.modalForm.value);
        }
      }, error => {
        if (error && error.error) {
          this.error = error.error.error;
          this.loading = false;
        }
      });
    }
    this.submitted = false;
  }

  onLaunchQuestionnaire(event) {
    this.submitted = true;
    this.loading = true;

    if (this.modalForm.invalid) {
      this.loading = false;
      return;
    }

    this.client = new Client();
    this.client = this.modalForm.value;

    if (this.config.data) {
      this.modalForm.value.client_estimated_date_ret = this.config.data.client_estimated_date_ret;
      this.error = null;
      this.loading = false;

      this.dialogService.open(ModalQuestionnaireComponent, {
        width: '100%',
        contentStyle: { 'height': '100vh', 'overflow': 'hidden', 'padding': 0 },
        styleClass: 'questionnaire-box',
        closable: false,
        showHeader: false,
        data: this.modalForm.value,
      });

      this.ref.close();
    } else {
      this.client.type = 'launch';
        this.http.post('clients/create-client', this.client, false, null).subscribe((client) => {
          if (client) {
            this.error = null;
            this.loading = false;
            this.dialogService.open(ModalQuestionnaireComponent, {
              width: '100%',
              contentStyle: { 'height': '100vh', 'overflow': 'hidden', 'padding': 0 },
              styleClass: 'questionnaire-box',
              closable: false,
              showHeader: false,
              data: this.modalForm.value,
            });
            this.ref.close();
          }
        }, error => {
          if (error && error.error) {
            this.error = error.error.error;
            this.loading = false;
          }
        });
    }

  }
}
