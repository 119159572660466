import {Injectable} from '@angular/core';
import {Constants} from '../constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { catchError, Subscription, throwError } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';


@Injectable({
  providedIn: 'root'
})
export class RpsService {
  token: string;

  constructor(private httpClient: HttpClient,
              private http: HttpRequestService,
              private angularFireAuth: AngularFireAuth) {
  }

  getAllQuestions(jwt, advisor_id, advisor_support = false) {
    const firebaseAuthToken = localStorage.getItem('accessToken');
    const token = advisor_support ? firebaseAuthToken : jwt;
    const resource = `?advisor_id=${advisor_id}${advisor_support  ? '&advisor_support=true' : '' }`;
    const headers = new HttpHeaders()
                .set('Authorization', 'Bearer ' + token)
                .set('Content-Type', 'application/json');

    return this.httpClient.get(Constants.endpoint.questionsForm + resource, { headers })
      .pipe(catchError(error => {
        this.http.checkError(error);
        console.log({error});
          return throwError(error);
      }));
  }

  getQuestionsByUser(advisor_id) {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      'Content-Type': 'application/json',
    };

    return this.httpClient.get(`${Constants.endpoint.questions}?advisor_id=${advisor_id}`, {headers});
  }

  getQuestionsByUserByDraft(advisor_id, status, only_edit) {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      'Content-Type': 'application/json',
    };

    return this.httpClient.get(`${Constants.endpoint.questions}?advisor_id=${advisor_id}&status=${status}&only_edit=${only_edit}`, {headers});
  }

  getActionPlanByUser(advisor_id, form_status?) {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      'Content-Type': 'application/json',
    };
    if (form_status) {
      return this.httpClient.get(`${Constants.endpoint.action_plan}?advisor_id=${advisor_id}&form_status=${form_status}`, {headers});
    }
    return this.httpClient.get(`${Constants.endpoint.action_plan}?advisor_id=${advisor_id}`, {headers});
  }

  sendResults(answers: any[], id?, token?: any, advisor_support?, origin?) {
    const body = {
      'event_id': id,
      'event_type': 'form_response',
      'advisor_support': advisor_support,
      form_response: {form_id: id, answers},
      origin :  origin

    };
    const headers = {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Typeform-Signature': 'sha256=AwQd8MN7/z6TR8naDaDrq6yIRLUhjRAw2nAAGxpTwXw='
    };
    return this.httpClient.post(Constants.endpoint.typeform, body, {headers});
  }

  getCountries() {
    return this.httpClient.get(environment.services.restCountries);
  }

  /**
   * signupAnonymo()
  * Sign up the user
  * @return the anonymous user's session token
  * @memberof RpsService
  */
   signupAnonymo(): Promise<string> {

    let subscription = new Subscription();
    return new Promise((resolve, reject) => {
      this.angularFireAuth.signInAnonymously().then(user => {
        subscription = this.angularFireAuth.idToken.subscribe((tokenId: string) => {
          /**
           * return user's session token
           */
          this.token = tokenId;
          resolve(tokenId);
          subscription.unsubscribe();
        });
      }).catch(err => {
        reject(err);
        subscription.unsubscribe();
      });
    });
  }
}
