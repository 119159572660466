import { Component, OnInit } from '@angular/core';
import { HttpRequestService } from 'src/app/core/services/http-request.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible', })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),
    trigger('modalShow', [
      state('show', style({ height: '*', opacity: 1, visibility: 'visible', display: 'flex' })),
      state('hide', style({ height: '0px', opacity: 0, visibility: 'hidden', display: 'none' })),
      transition('show <=> hide',
        animate('300ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),

    trigger('arrowShow', [
      state('show', style({ opacity: 1, display: 'block' })),
      state('hide', style({ opacity: 0, display: 'none' })),
      transition('show <=> hide',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('200ms ease-out')),
      transition('default => rotated', animate('200ms ease-in'))
    ])
  ]
})
export class HelpModalComponent implements OnInit {

  show = false;
  selectedItems: Array<number> = [];
  questions: Array<any> = [];

  constructor(private http: HttpRequestService) {}

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.http.get(`fqa`).subscribe({
      next: res => {
        this.questions = res;
      }
    });
  }

  openModal() {
    if (this.questions.length > 0) {
      this.show = true;
    }
  }
  closeModal() {
    this.show = false;
    this.selectedItems = [];
  }

  selectItem(item) {
    if (this.selectedItems.includes(item)) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    } else {
      this.selectedItems.push(item);
    }
  }

}
